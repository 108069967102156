import React, { useEffect } from 'react'; 
import { Route, Routes, useLocation } from 'react-router-dom';
import Nav from './nav';
import Hero from './Hero';
import About from './basics/About';
import Olympiad from './Olympaid/Olympiad';
import OlympiadDetails from './Olympaid/OlympiadDetails';  
import OurPartners from './basics/OurPartners';
import OurEducationPartners from './basics/OurEducationPartners';
import UpcomingCourses from './basics/UpcomingCourses';
import FAQ from './basics/FAQ';
import Footer from './basics/Footer';
import RegistrationPage from './Lander/RegistrationPage';
import LoginPage from './Lander/LoginPage';
import OtpVerificationPage from './Lander/OtpVerificationPage';
import Testimonials from './basics/Testimonials';
import Gallery from './basics/Gallery';
import Accreditation from './basics/Accreditation'; 
import './App.css';
import Policies from './basics/Policies';
import Contact from './basics/Contact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  
import Thanks from './Olympaid/ThankYou';
import SpaceScienceFacts from './basics/SpaceFacts';
import Header from './Header'
import Ceremony from './Ceremony/Ceremony';
import Inaguration from './Ceremony/Inaguration';
import MouSigning from './Ceremony/MouSigning';



function App() {
  const location = useLocation();

  // Show toast notification only if on the homepage
  useEffect(() => {
    // Check if we are on the home page
    if (location.pathname === '/') {
      toast.info("ISSO registration has been started. Click to know more", {
        position: 'top-center',
        autoClose: 5000,
        onClick: () => {
          const olympiadDetailsSection = document.getElementById('oly');
          if (olympiadDetailsSection) {
            olympiadDetailsSection.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        },
        style: {
          cursor: 'pointer',
        },
      });
    }
  }, [location.pathname]);  // Trigger the effect only on pathname change

  return (
    <div className="App">
      {/* Conditionally render the navbar based on the current route */}
      {location.pathname !== '/olympiad-details'
       && location.pathname !== '/contact' && location.pathname !== '/spacefacts'
       && location.pathname !== '/ceremony' && <Nav/>}  
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <About />
              <Olympiad />
              <OurPartners />
              <OurEducationPartners />
              <UpcomingCourses />
              <Gallery />
              <Testimonials />
              <Accreditation /> 
              <FAQ />
              <Footer />
            </>
          }
        />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/otp-verification" element={<OtpVerificationPage />} />
        <Route path="/olympiad-details" element={<OlympiadDetails />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thank-you" element={<Thanks />} />
        <Route path='/spacefacts' element={<SpaceScienceFacts/>}/>
        <Route path='/ceremony' element={<Ceremony/>}/>
        <Route path='/mou-signing' element={<MouSigning/>}/> 

        <Route path='/inauguration' element={<Inaguration/>} />

      </Routes>

      
    </div>
  );
}

export default App;
