import React, { useState } from "react";
import styles from "./Inaguration.module.css";


// Import images
import img1 from "./eventImages/inaguration/1.jpeg";
import img2 from "./eventImages/inaguration/2.jpeg";
import img4 from "./eventImages/inaguration/4.jpg";
import img5 from "./eventImages/inaguration/05.JPG";
import img6 from "./eventImages/inaguration/6.jpeg";
import img7 from "./eventImages/inaguration/7.jpeg";
import img8 from "./eventImages/inaguration/8.jpeg";
import img9 from "./eventImages/inaguration/9.jpeg";
import img10 from "./eventImages/inaguration/10.jpeg";
import img11 from "./eventImages/inaguration/11.jpeg";
import img12 from "./eventImages/inaguration/12.jpeg";
import img13 from "./eventImages/inaguration/13.jpeg";
import img14 from "./eventImages/inaguration/14.jpeg";
import img16 from "./eventImages/inaguration/16.jpg";
import img18 from "./eventImages/inaguration/18.jpg";


const Inaguration = () => {

    const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (img) => setSelectedImage(img);
  const closeModal = () => setSelectedImage(null);

  const images = [img1, img2, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img16, img18];


  return (
    <div className={styles.inagurationHeader}>
     {/* Event Header */}
     <div className={styles["ceremony-header"]}>
          <h1 className={styles["ceremony-title"]}>Inauguration of ISSO</h1>
          <div className={styles["ceremony-Sub"]}>
            <h2 className={styles["ceremony-subtitle"]}>Location: IN-SPACe Headquarters, Ahmedabad</h2>
            <p className={styles["ceremony-date"]}>Date: 3rd Feb 2025</p>
          </div>
        </div>

        {/* Gallery Section */}
        <div className={styles["ceremony-gallery"]}>
          <div className={styles["ceremony-gallery-images"]}>
            {images.map((img, index) => (
              <img key={index} src={img} alt={`Ceremony Event ${index + 1}`} onClick={() => openModal(img)} />
            ))}
          </div>
        </div>

        {/* Modal */}
        {selectedImage && (
          <div className={styles.modal} onClick={closeModal}>
            <div className={styles["modal-content"]} onClick={(e) => e.stopPropagation()}>
              <button className={styles["close-btn"]} onClick={closeModal}>✖</button>
              <img src={selectedImage} alt="Selected Event" />
            </div>
          </div>
        )}
    </div>
  )
}

export default Inaguration