import React from 'react';
import { Link } from 'react-router-dom';
import './hero.css';
import './Nav.css';

function Nav() {
  return (
    <nav className="navbar">
      <Link className="navbar-logo" to="/">
        <img className='logologo' src={require('./images/logo.png')} alt="Logo" />
        <img className='logo-text' src={require('./images/codi-text.png')} alt="Logo-text" />
      </Link>
      <ul className="navbar-links">
        <li><a href="/">Home</a></li>
        <li><a href="#oly">Announcements</a></li>
        <li><Link to="/ceremony">Events</Link></li>
        <li><a href="#about">About</a></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Nav;
