import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Ceremony.module.css";
import Header from "../Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import banner1 from "./eventImages/inaguration/MOU.jpg";
import banner2 from "./eventImages/inaguration/banner2.jpeg";
import banner3 from "./eventImages/inaguration/banner3.jpeg";

const Ceremony = () => {
  const navigate = useNavigate();

  const [cardsData, setCardsData] = useState([
    {
      id: 1,
      title: "IN-SPACe Inauguration",
      date: "3-02-2025",
      description: "Codimaths inaugurated at IN-SPACe, Ahmedabad, advancing space education.",
      image: banner2,
      route: "/inauguration",
    },
    // {
    //   id: 2,
    //   title: "Sky-Gazing Event",
    //   date: "2025-02-28",
    //   description: "A breathtaking sky-gazing event with telescopes and expert guidance.",
    //   image: banner2,
    //   route: "/sky-gazing",
    // },
    {
      id: 3,
      title: "MOU Signing with IEEE AESS",
      date: "26-02-25",
      description: "Codimaths signed an MOU with IEEE AESS Gujarat Chapter",
      image: banner1, 
      route: "/mou-signing", 
    },
  ]);


  return (
    <>
      <Header />
      <div className={styles["ceremony-container"]}>
        {/* Banner Slider */}
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          modules={[Autoplay, Pagination]}
          className={styles["ceremony-slider"]}
        >
          {[banner1, banner2, banner3].map((img, index) => (
            <SwiperSlide key={index}>
              <img src={img} alt={`Slide ${index + 1}`} className={styles["slider-image"]} />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Past Events Heading */}
        <h1 className={styles["events-heading"]}>Past Events</h1>
        <p>Codimaths has successfully organized several impactful events, including:</p>

        {/* Cards Section */}
        <div className={styles["cards-container"]}>
          {cardsData.map((card) => (
            <div key={card.id} className={styles["card"]}>
              <img src={card.image} alt={card.title} className={styles["card-image"]} />
              <div className={styles["card-content"]}>
                <h3 className={styles["card-title"]}>{card.title}</h3>
                <p className={styles["card-date"]}>Date: {card.date}</p>
                <p className={styles["card-description"]}>{card.description}</p>
                <button 
                  className={styles["explore-button"]} 
                  onClick={() => navigate(card.route)}
                >
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>    
      </div>
    </>
  );
};

export default Ceremony;
