import React, { useState } from 'react'; // Add 'useState' for modal functionality
import styles from "./MouSigning.module.css";

// Import images
import img1 from "./eventImages/mou/img1.jpg";
import img2 from "./eventImages/mou/img2.jpg";
import img3 from "./eventImages/mou/img3.jpg";

const MouSigning = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (img) => setSelectedImage(img);
    const closeModal = () => setSelectedImage(null);

    const images = [img1, img2, img3];
  return (
  <div className={styles.mouHeader}>
  {/* Event Header */}
  <div className={styles["ceremony-header"]}>
                <h1 className={styles["ceremony-title"]}>MOU between CodiMaths and IEEE AESS Gujarat Chapter</h1>
                {/* <div className={styles["ceremony-Sub"]}> */}
                    {/* <h2 className={styles["ceremony-subtitle"]}>Location: IN-SPACe Headquarters, Ahmedabad</h2> */}
                    <p className={styles["ceremony-date"]}>Date: 26th Feb 2025</p>
                </div>
            {/* </div> */}

            {/* Gallery Section */}
            <div className={styles["ceremony-gallery"]}>
                <div className={styles["ceremony-gallery-images"]}>
                    {images.map((img, index) => (
                        <img key={index} src={img} alt={`Ceremony Event ${index + 1}`} onClick={() => openModal(img)} />
                    ))}
                </div>
            </div>

            {/* Modal */}
            {selectedImage && (
                <div className={styles.modal} onClick={closeModal}>
                    <div className={styles["modal-content"]} onClick={(e) => e.stopPropagation()}>
                        <button className={styles["close-btn"]} onClick={closeModal}>✖</button>
                        <img src={selectedImage} alt="Selected Event" />
                    </div>
                </div>
            )}
        </div>
  )
}

export default MouSigning