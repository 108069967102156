import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import styles from './Header.module.css'; // Import CSS module

function Header() {
  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <nav className={styles.navbar}>
        <Link className={styles.navbarLogo} to="/">
          <img className={styles.logologo} src={require('./images/logo.png')} alt="Logo" />
          <img className={styles.logoText} src={require('./images/codi-text.png')} alt="Logo-text" />
        </Link>
        <ul className={styles.navbarLinks}>
          <li><Link to="/">Home</Link></li>
          {/* <li onClick={toggleModal}>Upcoming Events</li> */}
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>

      {/* Modal for Upcoming Events */}
      {/* {showModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <span className={styles.closeBtn} onClick={toggleModal}>&times;</span>
            <h2>Upcoming Event <br/> ✨ Star Gazing Night ✨</h2>
            <p>Join us for an exciting night of stargazing! Witness the beauty of the cosmos with telescopes and expert guidance.</p>
            <p><strong>Date:</strong> 15th March 2025</p>
            <p><strong>Location:</strong> Codimaths Observatory, Ahmedabad</p>
            <p><strong>Time:</strong> 7:30 PM - 11:00 PM</p>
          </div>
        </div>
      )} */}
    </>
  );
}

export default Header;
