import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './olympiad-details.css'; 
import issoLogo from '../images/isso.png';
import { useUserState } from '../helper';
import { FaRocket, FaBrain, FaTrophy, FaChalkboardTeacher } from 'react-icons/fa';
import QuizApp from './QuizApp.js'

function OlympiadDetails() {
  const [loading, setLoading] = useState(false);
  const { storeData, fetch } = useUserState();
  const [formData, setFormData] = useState({
    full_name: '',
    first_name: '',
    last_name: '',
    age: '',
    phone_number: '',
    email: '',
    school: '',
    country: '',
    olympiad_id: 1,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data, status } = await fetch({
        url: 'olympiad-registration/',
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      });

      setLoading(false);
      if (status === 201) {
        storeData(data);
        toast.success('Registration successful! Redirecting to OTP verification...');
        navigate('/otp-verification');
      } else if (status === 208) {
        storeData(data);
        toast.info('Already registered! Redirecting to Thank You page...');
        navigate('/thank-you');
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Registration failed. Please try again later. ${error.message}`);
    }
  };

  const handleFullName = (e) => {
    const FullName = e.target.value;
    const names = FullName.trim().split(' ');
    setFormData({
      ...formData,
      full_name: FullName,
      first_name: names[0],
      last_name: names.length > 1 ? names[names.length - 1] : '',
    });
  };

  const handleFileOpen = async (fileId, event) => {
    event.preventDefault();
    try {
      const response = await fetch({
        url: `get_paper/?id=${fileId}`,
        method: 'GET',
        responseType: 'blob',
      });
      if (response.status !== 200) {
        toast.error("Couldn't load file. Internal server issue!");
        throw new Error('Failed to fetch the file.');
      }
      if (response.status === 200) {
        const fileURL = window.URL.createObjectURL(response.data);
        window.open(fileURL, '_blank');
      }
    } catch (error) {
      toast.error('Failed to load file. Please try again.');
    }
  };

  return (
    <div className="olympiad-details-page">
      <ToastContainer /> {/* Toast Container for notifications */}
      
      <nav className="olympiad-details-page__navbar">
        <div className="olympiad-details-page__logo">
          <img src={issoLogo} alt="ISSO Logo" />
        </div>
        <ul className="olympiad-details-page__navbar-links">
          <li><a href="/">Home</a></li>
          <li><a href="#about">About the Olympiad</a></li>
          {/* <li><a href="#practice">Practice Papers</a></li> */}
          <li><Link to="/spacefacts">Space Science Facts</Link></li> 
          <li><a href="#quiz">Mock Quiz</a></li>
          <li><a href="./contact">Contact Us</a></li>
        </ul>
      </nav>

      <section className="olympiad-details-page__registration-section">
        <h2 className="olympiad-details-page__section-title">Olympiad Registration</h2>
        <form onSubmit={handleSubmit} className="olympiad-details-page__registration-form">
          {/* Registration Form Fields */}
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              value={formData.full_name}
              onChange={handleFullName}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="age">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              min="0"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone_number">Phone Number</label>
            <input
              type="tel"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
         
          <button
            type="submit"
            className="olympiad-details-page__register-btn"
            disabled={loading}
          >
            {loading ? <div className="spinner"></div> : 'Register Now'}
          </button>
        </form>
      </section>

          {/* About Section */}
<section id="about" className="olympiad-details-page__about-section">
  <h2 className="olympiad-details-page__section-title">About the Olympiad</h2>
  <div className="olympiad-details-page__about-icons">
    <div className="olympiad-details-page__about-icon-box">
      <FaRocket className="icon" />
      <h3>About the Olympiad</h3>
      <p>India is hosting the Olympiad with the support of IN-SPACe (Indian National Space Promotion and Authorisation Centre).It is a three-tier examination.</p>
    </div>
    <div className="olympiad-details-page__about-icon-box">
      <FaBrain className="icon" />
      <h3>Develop Critical Thinking</h3>
      <p>Enhance your problem-solving abilities and creative thinking while tackling complex challenges in scientific space field</p>
    </div>
    <div className="olympiad-details-page__about-icon-box">
      <FaTrophy className="icon" />
      <h3>Achieve Excellence</h3>
      <p>This is the first-of-its-kind space science Olympiad, where winners from every country will compete for gold, silver, and bronze medals.</p>
   
    </div>
    <div className="olympiad-details-page__about-icon-box">
      <FaChalkboardTeacher className="icon" />
      <h3>Learn from Experts</h3>
      <p> Participants will also have the unique opportunity to exchange their ideas and collaborate on a project under the guidance of senior Space Scientists.</p>
    </div>
  </div>
</section>

   {/* Quiz Section */}
   <section id="quiz" className="olympiad-details-page__quiz-section">
        <QuizApp /> {/* Render QuizApp component */}
      </section>

      {/* Practice Papers Section */}
      <section id="practice" className="olympiad-details-page__practice-section">
        <h2 className="olympiad-details-page__section-title">Practice Papers</h2>
        <p className="olympiad-details-page__section-text">
          Download the sample practice papers below to get started with your preparation:
        </p>
        <ul className="olympiad-details-page__practice-papers-list">
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(5, event)}>Sample Paper 1 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(6, event)}>Sample Paper 2 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(7, event)}>Sample Paper 3 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(8, event)}>Sample Paper 4 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(9, event)}>Sample Paper 5 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(10, event)}>Sample Paper 6 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(11, event)}>Sample Paper 7 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(12, event)}>Sample Paper 8 (PDF)</button>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default OlympiadDetails;